import axios from "axios";
import { CONFIG } from './config';
import {  toast } from 'react-toastify';

const API_BASE_URL = CONFIG.api_base_url + "api/v1/"

// Create a custom instance of Axios with the desired configuration options
const tbcApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials:true
});

// Add an interceptor to add JWT token to Authorization header for authenticated requests
tbcApi.interceptors.request.use((config) => {
  //console.log('sssssss', config)
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

tbcApi.interceptors.response.use(
    (response) => {
        //console.log('response', response); 
        return response},
    (error) => {
        // Reject promise if usual error
        console.log(error,error.message, error.response?.data?.error?.debug_msg, "--error")

        if (error.response.status !== 408 && error.response.status !== 501) {
          if(error.response?.data?.error?.debug_msg !== undefined){
            toast.error(error.response.data.error.debug_msg===""?error.response.data.error.msg:error.response.data.error.debug_msg);
          }else if(error.message !== undefined){
            toast.error(error.message);
          }
        }

        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        /*
         * go back if api gives 401
         */

        if (error.response.status == 401 || error.response.status == 403) {
            request(`user-mgmt/user/logout`, methods.DELETE);
        }
        toast.error('Session expired. Please login again.');
        localStorage.clear()
        
        const checkApp = window.location.href.indexOf("app");
        
        if(checkApp > 0){
          window.location.href = `/login?redirectUri=${window.location.href}`;
        }else{
          window.location.href = `/pwa-login`;
        }

      }
);

// Add an interceptor to add JWT token to Authorization header for authenticated requests
// tbcApi.interceptors.response.use((config) => {
//   console.log('interceptors', config)
//   return config;
// });

export const methods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// eslint-disable-next-line no-unused-vars
export const request = async (url, method, _data = {}, _config = {}) => {
  try {
    let result;

    const config = Object.assign({}, _config, {
      headers: Object.assign({}, tbcApi.defaults.headers, _config.headers),
    });

    switch (method) {
      case "GET": {
        result = await tbcApi.get(url);
        break;
      }
      case "POST": {
        result = await tbcApi.post(url, _data, config);
        break;
      }
      case "PUT": {
        result = await tbcApi.put(url, _data, config);
        break;
      }
      case "PATCH": {
        result = await tbcApi.patch(url, _data, config);
        break;
      }
      case "DELETE": {
        result = await tbcApi.delete(url);
        break;
      }
      default: {
        // eslint-disable-next-line no-throw-literal
        throw "InvalidMethod";
      }
    }
    const { data } = result;
    return [null, data];
  } catch (error) {
    const errDto = {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data,
    };
    return [errDto];
  }
};
